export class LocalFCNOrder{
	
	public id: number;
	public order_id: number;
	public code: string;
	public status_id: number;
	public status: string;
	public mosque: string;
	public amount: string;
	public receipt: string;
	
}